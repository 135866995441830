<template>
  <div style="padding: 1rem;">
  
    <b-card>
      <b-row class="mb-1 pl-1 pr-0">
        <b-col>
          <h4>{{$t('Register edition')}}</h4>
          <!-- <h6>{{$t('Access to Property', {house: (accessData.housingnumber || '') + ' | ' +(accessData.resortsname || '')})}}</h6> -->
        </b-col>
        <b-col>
          <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'my-property' }">
            <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
          </b-button>
        </b-col>
      </b-row>


      <AccessForm
        v-if="showInitialForm"
        :isLoadingControlAccessData="isLoadingControlAccessData"
        :isSavingControlAccessData="isSavingControlAccessData"
        :accessData="accessData"
        :originalAccessData="accessData"
        @save-register="saveRecord"
      />

      <div v-else class="text-center">
        <b-spinner class="mt-1"/> <br>
        {{ $t('Loading details') }}
      </div>

    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AccessForm from '@/modules/myProperty/components/AccessControl/AccessForm'
import { showAlertMessage, makeTime } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
    mixins:[ utils ],
	async created() {
		await this.init()
	},
	components: {
		AccessForm,
		
	},
	data() {
		return {
			isLoadingControlAccessData: false,
			isSavingControlAccessData: false,
			showInitialForm: false,
			accessData: {
				name: null,
				lastname: null,
				email: null,
				reason: null,
				type: null,
				guestData: [],
				fullDay: true,
				dateIn: null,
				dateOut: null,
				timeIn: null,
				timeOut: null
			},
			originalAccessData: null
		}
	},
	computed:{
		...mapState('auth', ['user', 'myContracts']),
	},
	methods:{
    	...mapActions('myProperty', ['fetchControlAccess', 'updateControlAccess']),
		async init(){
		const idAccess = this.$route.params.id
			if(this.isANumber(idAccess)){
				this.isLoadingControlAccessData = true
				this.showInitialForm = false
				const accessRegister = await this.fetchControlAccess({idAccess})
				this.accessData = structuredClone(accessRegister)
				this.originalAccessData = structuredClone(accessRegister)
				// await makeTime(400)
				this.isLoadingControlAccessData = false
				this.showInitialForm = true
				if (!this.accessData.active) this.$router.push({name: 'access-control', params: { id: idAccess } })
			}
		},
    isANumber(string){
      	return string ? !isNaN( string ) && string > 0 : false
    },
    async saveRecord(data){
		// validar si es admin desde mixin en utils
		if( this.isAdmin ){
			return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
		}

		this.isSavingControlAccessData = true
		data.idOwner = this.user.idOwner
		data.idContract = this.myContracts.contractSelected.id
		const response = await this.updateControlAccess(data)
		this.isSavingControlAccessData = false
		if (response) {
			showAlertMessage(this.$t('Access updated'), 'InfoIcon', `🎉🎉 ${this.$t('Register updated')}`, 'success', 4000, 'bottom-right')
			this.$router.push({name: 'my-property'})
			await this.pushData('onSubmit', 'accessControlLogWasUpdated', 'accessControlLogWasUpdated', this.$route.name) // push a back del registro
		}
		if( !response ){
			showAlertMessage( this.$t('something failed'), 'InfoIcon', this.$t(`try again`), 'danger', 4000, 'bottom-right')
		}
	}
  },
}

</script>

<style>

</style>